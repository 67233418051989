<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="条码" prop="sn">
                    <el-input v-model="searchForm.sn" size="mini"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQueryBtnClickEvent" size="mini">查询</el-button>
                    <el-button type="primary" @click="openImportPvWarrantyInfoDialog" size="mini">导入</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" border size="small" stripe style="width: 100%" height="550"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                <el-table-column prop="model" label="型号" width="115px"/>
                <el-table-column prop="packageNum" label="包装编码" />
                <el-table-column prop="sn" label="条码" />
                <el-table-column prop="voc" label="Voc(V)" width="80px" align="center"/>
                <el-table-column prop="isc" label="Isc(A)" width="80px" align="center"/>
                <el-table-column prop="pmax" label="Pmax(W)" width="80px" align="center"/>
                <el-table-column prop="vmp" label="Vmp(V)" width="80px" align="center"/>
                <el-table-column prop="imp" label="Imp(A)" width="80px" align="center"/>
                <el-table-column prop="ff" label="FF(%)" width="80px" align="center" />
                <el-table-column prop="exportArea" label="出口地区" width="100px" align="center" />
                <el-table-column prop="productionTime" label="生产时间" width="100px" align="center"/>
                <el-table-column prop="profileTime" label="外观质保时间" width="100px" align="center" />
                <el-table-column prop="efficiencyTime" label="效率质保时间" width="100px" align="center" />
                <el-table-column label="操作" width="80px" align="center">
                    <template slot-scope="scope">
                        <div style="" class="operate-btn-container">
                            <div @click="openEditPvWarrantyInfoDialog(scope.$index, scope.row)">
                                <el-tooltip content="编辑" placement="top">
                                    <el-icon class="el-icon-edit" style="color: #409EFF"></el-icon>
                                </el-tooltip>
                            </div>
                            <div @click="removePvWarrantyInfo(scope.$index, scope.row)">
                                <el-tooltip content="删除" placement="top" >
                                    <el-icon class="el-icon-delete" style="color: #F56C6C"></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 15px;text-align: right;">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClickEvent"
                    @next-click="handleNextClickEvent"
                    :current-page.sync="searchForm.pageNum"
                    :page-sizes="[10, 50, 100]"
                    :page-size="searchForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"/>
            </div>
        </div>
        <el-dialog title="数据导入" :visible.sync="dataImportVisible" center :close-on-click-modal="false" width="600px">
            <div style="height: 130px;">
                <el-upload ref="fileUpload"
                           action="/doartIot/es-manager/deviceWarrantyInfo/importPvDeviceWarrantyInfo"
                           accept=".xls,.xlsx"
                           :limit="1"
                           :auto-upload="false"
                           :show-file-list="true"
                           :multiple="false"
                           :file-list="fileList"
                           :headers="headers"
                           list-type="picture"
                           :on-success="fileUploadSuccessEvent"
                           :on-change="fileChangeEvent"
                           :on-error="fileUploadErrorEvent">
                    <el-button size="small" type="primary" style="margin-bottom: 6px;">请选择文件</el-button>
                    <div slot="tip">只能上传 <span style="color: red;">.xls</span> 和 <span style="color: red;">.xlsx</span> 格式文件</div>
                </el-upload>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="downloadTemplate">下载模板</el-button>
                <el-button size="small" type="primary" @click="importPvWarrantyInfo" :loading="importLoading">导入数据</el-button>
                <el-button size="small" @click="closeImportPvWarrantyInfoDialog">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="导入异常提示信息" :visible.sync="errorInfoVisible" center :close-on-click-modal="false" width="800px">
            <div style="height: 400px; overflow: auto">
                <div style="color: red;margin-bottom: 4px;">存在{{errorData.length}}行异常的数据，请检查！</div>
                <el-table :data="errorData" border size="small" stripe style="width: 100%;"
                          :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                    <el-table-column prop="rowNumber" label="行号" width="80" align="center"/>
                    <el-table-column prop="sn" label="条码" />
                    <el-table-column prop="faultDesc" label="说明"/>
                </el-table>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="errorInfoVisible=false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改信息" :visible.sync="editFormVisible" center :close-on-click-modal="false" width="800px">
            <el-form :model="editForm" ref="editForm" :rules="rules" label-width="110px" :inline="true">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="型号" prop="model">
                            <el-input v-model="editForm.model" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="包装编码" prop="packageNum">
                            <el-input v-model="editForm.packageNum" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="条码" prop="sn">
                            <el-input v-model="editForm.sn" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Voc" prop="voc">
                            <el-input v-model="editForm.voc" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="Isc" prop="isc">
                            <el-input v-model="editForm.isc" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Pmax" prop="pmax">
                            <el-input v-model="editForm.pmax" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="Vmp" prop="vmp">
                            <el-input v-model="editForm.vmp" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Imp" prop="imp">
                            <el-input v-model="editForm.imp" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="FF" prop="ff">
                            <el-input v-model="editForm.ff" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出口地区" prop="exportArea">
                            <el-input v-model="editForm.exportArea" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="生产时间" prop="productionTime">
                            <el-input v-model="editForm.productionTime" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="外观质保时间" prop="profileTime">
                            <el-input v-model="editForm.profileTime" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="效率质保时间" prop="efficiencyTime">
                            <el-input v-model="editForm.efficiencyTime" autocomplete="off" style="width: 254px"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="editPvWarrantyInfoHandle">确定</el-button>
                <el-button @click="closeEditPvWarrantyInfoDialog">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {getToken} from "@/api/auth";
    import NavBreadcrumb from '@/components/NavBreadcrumb';
    import {queryPvDeviceWarrantyInfoByCondition, editPvWarrantyInfo, removePvWarrantyInfo} from "../../../api/esaio/pvWarrantyInfoApi";

    export default {

        name: 'PvWarrantyInfo',
        data() {

            return {

                listLoading: false,
                tableTotal: 0,
                tableData: [],
                errorData: [],
                searchForm: {

                    pageNum: 1,
                    pageSize: 10,
                    sn: ''
                },
                editForm: {

                    id: '',
                    model: '',
                    packageNum: '',
                    sn: '',
                    voc: '',
                    isc: '',
                    pmax: '',
                    vmp: '',
                    imp: '',
                    ff: '',
                    exportArea: '',
                    productionTime: '',
                    profileTime: '',
                    efficiencyTime: ''
                },
                rules: {

                    model: [{ required: true, message: '请输入型号', trigger: 'blur' }],
                    packageNum: [{ required: true, message: '请输入包装编码', trigger: 'blur' }],
                    sn:  [{ required: true, message: '请输入条码', trigger: 'blur' }],
                    voc: [{ required: true, message: '请输入Voc', trigger: 'blur' }],
                    isc: [{ required: true, message: '请输入Isc', trigger: 'blur' }],
                    pmax: [{ required: true, message: '请输入Pmax', trigger: 'blur' }],
                    vmp: [{ required: true, message: '请输入Vmp', trigger: 'blur' }],
                    imp: [{ required: true, message: '请输入Imp', trigger: 'blur' }],
                    ff: [{ required: true, message: '请输入FF', trigger: 'blur' }],
                    exportArea: [{ required: true, message: '请输入出口地区', trigger: 'blur' }],
                    productionTime: [{ required: true, message: '请输入生产时间', trigger: 'blur' }],
                    profileTime: [{ required: true, message: '请输入外观质保时间', trigger: 'blur' }],
                    efficiencyTime: [{ required: true, message: '请输入效率质保时间', trigger: 'blur' }]
                },
                headers: {

                    'Authorization' : 'Bearer ' + getToken()
                },
                dataImportVisible: false,
                importLoading: false,
                errorInfoVisible: false,
                editFormVisible: false,
                fileList: []
            };
        },
        components: {

            NavBreadcrumb
        },
        mounted() {

            this.queryPvWarrantyInfo();
        },
        methods: {

            queryPvWarrantyInfo() {

                queryPvDeviceWarrantyInfoByCondition(this.searchForm).then(res => {

                    if (res.code === 200) {

                        this.tableData = res.data.result;
                        this.tableTotal = res.data.total;
                    }
                });
            },
            openImportPvWarrantyInfoDialog() {

                this.dataImportVisible = true;
            },
            closeImportPvWarrantyInfoDialog() {

                this.dataImportVisible = false;
                this.$refs.fileUpload.clearFiles();
            },
            importPvWarrantyInfo() {

                this.$refs.fileUpload.submit();
            },
            fileUploadSuccessEvent(response) {

                this.$refs.fileUpload.clearFiles();
                this.queryPvWarrantyInfo();
                if (response.code === 200) {

                    this.$message({

                        showClose: true,
                        message: '数据导入成功！',
                        type: 'success'
                    });
                    this.dataImportVisible = false;
                    this.$refs.fileUpload.clearFiles();
                } else {

                    this.errorData = response.data;
                    if (this.errorData.length > 0) {

                        this.errorInfoVisible = true;
                        this.$message({

                            showClose: true,
                            message: '导入数据存在异常，请检查！',
                            type: 'error'
                        });
                    } else {

                        this.$message({

                            showClose: true,
                            message: '数据导入失败，请重试！',
                            type: 'error'
                        });
                    }
                }
            },
            fileUploadErrorEvent() {

                this.$message({

                    showClose: true,
                    message: '文件上传失败，请重试！',
                    type: 'error'
                });
            },
            fileChangeEvent(file) {

                file.url = require('../../../assets/image/excel.png');
            },
            openEditPvWarrantyInfoDialog(index, row) {

                this.editFormVisible = true;
                this.$nextTick(() => {

                    this.$refs['editForm'].clearValidate();
                    this.editForm.id = row.id;
                    this.editForm.model = row.model;
                    this.editForm.packageNum = row.packageNum;
                    this.editForm.sn = row.sn;
                    this.editForm.voc = row.voc;
                    this.editForm.isc = row.isc;
                    this.editForm.pmax = row.pmax;
                    this.editForm.vmp = row.vmp;
                    this.editForm.imp = row.imp;
                    this.editForm.ff = row.ff;
                    this.editForm.exportArea = row.exportArea;
                    this.editForm.productionTime = row.productionTime;
                    this.editForm.profileTime = row.profileTime;
                    this.editForm.efficiencyTime = row.efficiencyTime;
                });
            },
            closeEditPvWarrantyInfoDialog() {

                this.editFormVisible = false;
            },
            editPvWarrantyInfoHandle() {

                this.$refs['editForm'].validate((valid) => {

                    if (valid) {

                        editPvWarrantyInfo(this.editForm).then(res => {

                            if (res.code === 200) {

                                this.$message({

                                    showClose: true,
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.queryPvWarrantyInfo();
                                this.closeEditPvWarrantyInfoDialog();
                            } else {

                                if (res.msg !== null && res.msg.indexOf('请检查') > 0) {

                                    // 设置条码重复的提示信息
                                    this.$refs['editForm'].fields[2].validateState='error';
                                    this.$refs['editForm'].fields[2].validateMessage=res.msg;
                                }
                            }
                        });
                    } else {

                        return false;
                    }
                });
            },
            removePvWarrantyInfo(index, row) {

                this.$confirm('是否删除当前光伏组件售后信息？', '提示', {

                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {

                    this.editForm = row;
                    removePvWarrantyInfo(this.editForm).then(res => {

                        if (res.code === 200) {

                            this.$message({

                                showClose: true,
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.queryPvWarrantyInfo();
                        } else {

                            this.$message({

                                showClose: true,
                                type: 'error',
                                message: '删除失败，请重试!'
                            });
                        }
                    });
                })
            },
            handleQueryBtnClickEvent() {

                this.searchForm.pageNum = 1;
                this.queryPvWarrantyInfo();
            },
            handleCurrentChange(val) {

                this.searchForm.pageNum = val;
                this.queryPvWarrantyInfo();
            },
            handleSizeChange(val) {

                this.searchForm.pageSize = val;
                this.queryPvWarrantyInfo();
            },
            handlePrevClickEvent() {

                this.searchForm.pageNum = this.searchForm.pageNum - 1;
                this.queryPvWarrantyInfo();
            },
            handleNextClickEvent() {

                this.searchForm.pageNum = this.searchForm.pageNum + 1;
                this.queryPvWarrantyInfo();

            },
            downloadTemplate() {

                window.location.href = '/manager/template/PvWarrantyTemplate.xlsx';
            }
        }
    }
</script>
<style lang="scss" scoped>
    .operate-btn-container {

        display: flex;
        justify-content: space-around;
        cursor: pointer;
        font-size: 20px;
    }
</style>